import axios from '@/axios.js'

const state = () => ({
    users: [],
})

const mutations = {
  SET_USERS (state, payload) {
    state.users = payload
  },
  ADD_USERS (state, item) {
    state.users.unshift(item)
  },
  UPDATE_USERS (state, users) {
    const usersIndex = state.users.findIndex((p) => p.id === users.id)
    Object.assign(state.users[usersIndex], users)
  },
  REMOVE_USERS (state, itemId) {
    const ItemIndex = state.users.findIndex((p) => p.id === itemId)
    state.users.splice(ItemIndex, 1)
  },
}

const actions = {
  fetchUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/users-list')
        .then((response) => {
          commit('SET_USERS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addUsers ({ commit }, users ) {
    return new Promise((resolve, reject) => {
      axios.post('/users-add', users)
        .then((response) => {
          commit('ADD_USERS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateUsers ({ commit }, users) {
    return new Promise((resolve, reject) => {
      axios.post(`/users-update/${users.id}`, users)
        .then((response) => {
          commit('UPDATE_USERS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeUsers ({ commit }, usersId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/users-delete/${usersId}`)
        .then((response) => {
          commit('REMOVE_USERS', usersId)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}
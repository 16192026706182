
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} USER</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <vs-row>

          <vs-col vs-align="center" vs-w="6">
            <vs-input label="Username" v-model="dataUsername" class="mt-5 w-full pr-2" name="Username" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('Username')">{{ errors.first('Username') }}</span>
          </vs-col>

          <vs-col vs-align="center" vs-w="6">
            <vs-input :label="Object.entries(this.data).length === 0 ? 'New Password' : 'Update Password'" v-model="dataPassword" class="mt-5 w-full pl-2" name="Password" />
          </vs-col>

        </vs-row>

        <vs-row>

          <vs-col vs-align="center" vs-w="6">
            <vs-input label="Nicename" v-model="dataNicename" class="mt-5 w-full pr-2" name="Nicename" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('Nicename')">{{ errors.first('Nicename') }}</span>
          </vs-col>
          <vs-col vs-align="center" vs-w="6">
            <vs-input label="Display Name" v-model="dataDisplay" class="mt-5 w-full pl-2" name="Display Name" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('Display Name')">{{ errors.first('Display Name') }}</span>
          </vs-col>

        </vs-row>

        <vs-row>

          <vs-col vs-align="center" vs-w="6">
            <vs-input label="First Name" v-model="dataFirstName" class="mt-5 w-full pr-2" name="First Name" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('First Name')">{{ errors.first('First Name') }}</span>
          </vs-col>
          <vs-col vs-align="center" vs-w="6">
            <vs-input label="Last Name" v-model="dataLastName" class="mt-5 w-full pl-2" name="Last Name" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('Last Name')">{{ errors.first('Last Name') }}</span>
          </vs-col>

        </vs-row>
        
        <vs-row>

          <vs-col vs-align="center" vs-w="6">
            <vs-input label="Email" v-model="dataEmail" class="mt-5 w-full pr-2" name="Email" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
          </vs-col>

          <vs-col vs-align="center" vs-w="6">
            <vs-input label="Phone" v-model="dataPhone" class="mt-5 w-full pl-2" name="Phone" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('Phone')">{{ errors.first('Phone') }}</span>
          </vs-col>

        </vs-row>
        
        <vs-row>

          <vs-col vs-align="center" vs-w="6">
            <vs-input label="URL" v-model="dataURL" class="mt-5 w-full pr-2" name="URL" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('URL')">{{ errors.first('URL') }}</span>
          </vs-col>

          <vs-col vs-align="center" vs-w="6">
            <vs-select v-model="dataOtoritas" label="Otoritas" class="mt-5 w-full pl-2" name="Otoritas" >
              <vs-select-item :key="item.id" :value="item.id" :text="item.name" v-for="item in roles" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('Otoritas')">{{ errors.first('Otoritas') }}</span>
          </vs-col>

        </vs-row>

        <vs-row>

          <vs-col vs-align="center" vs-w="6">
            <div class="mt-5"></div>
            <label class="vs-input--label">Status</label>
            <div class="">
                <vs-radio v-model="dataStatus" vs-name="Status" vs-value="1" class="mr-4">TRUE</vs-radio>
                <vs-radio v-model="dataStatus" vs-name="Status" vs-value="0">FALSE</vs-radio>
            </div>
          </vs-col>

          <vs-col vs-w="6">
            <div class="mt-5"></div>
            <label class="vs-input--label">Permission</label><br>
            <div class="ml-4" v-for="item in pilih_permission" :key="item.id">
                <input type="checkbox" name="Permission" :value="item.name" v-model="dataPermission"/> {{ item.name }}
            </div>
            <span class="text-danger text-sm" v-show="errors.has('Permission')">{{ errors.first('Permission') }}</span>
          </vs-col>

        </vs-row>

        <vs-textarea label="Description" v-model="dataDescription" class="mt-5 w-full" />

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {

      dataId: null,
      dataUsername: null,
      dataPassword: null,
      dataNicename: null,
      dataEmail: null,
      dataPhone: null,
      dataURL: null,

      dataStatus: true,
      dataDisplay: null,
      dataPermission: ['R'],
      dataFirstName: null,
      dataLastName: null,
      dataDescription: null,
      dataOtoritas: null,

      pilih_permission: [{id: 1, name: "R"}, {id: 2, name: "E"}, {id: 3, name: "A"}, {id: 4, name: "D"}],

      roles: [],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        //this.initValues()
        this.$validator.reset()
      } else {
        const { id, user_login, user_nicename, user_email, user_phone, user_url, user_status, display_name, user_permission, first_name, last_name, description, user_role } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataUsername = user_login
        this.dataNicename = user_nicename
        this.dataEmail = user_email
        this.dataPhone = user_phone
        this.dataURL = user_url

        this.dataStatus = user_status
        this.dataDisplay = display_name
        this.dataPermission = user_permission.split(',')
        this.dataFirstName = first_name
        this.dataLastName = last_name
        this.dataDescription = description
        this.dataOtoritas = user_role
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid () {
      return !this.errors.any() && this.dataUsername && this.dataNicename && this.dataEmail && this.dataPhone && this.dataStatus && this.dataDisplay && this.dataPermission && this.dataOtoritas
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
  },
  mounted() {
    let configs = {
      headers: {
        'Authorization': 'Bearer ' + this.user.api_token,
      },
    }
    this.axios.get('/term-list/role', configs)
    .then((response) => {
      let {data} = response.data
      this.roles = data
    })
    .catch((error) => {
      let data = error.data
      this.roles = data
    })
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.dataId = null
      this.dataUsername = null
      this.dataPassword = null
      this.dataNicename = null
      this.dataEmail = null
      this.dataPhone = null
      this.dataURL = null

      this.dataStatus = true
      this.dataDisplay = null
      this.dataPermission = ['R']
      this.dataFirstName  = null
      this.dataLastName = null
      this.dataDescription = null
      this.dataOtoritas = null
    },
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id : this.dataId,
            user_login : this.dataUsername,
            user_pass : this.dataPassword,
            user_nicename : this.dataNicename,
            user_email : this.dataEmail,
            user_phone : this.dataPhone,
            user_url : this.dataURL,

            user_status : this.dataStatus,
            display_name : this.dataDisplay,
            user_permission : this.dataPermission.toString(),
            
            meta : {
              first_name : this.dataFirstName,
              last_name : this.dataLastName,
              description : this.dataDescription,
              user_role : this.dataOtoritas
            }
          }

          if (this.dataId !== null && this.dataId >= 0) {
            this.$store.dispatch('user/updateUsers', obj)
            .then(response => {
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.$emit('closeSidebar')
              this.initValues()
            })
            .catch(error => { 
              this.$vs.notify({
                title: 'Error',
                text: error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          } else {
            delete obj.id
            this.$store.dispatch('user/addUsers', obj)
            .then(response => {
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.$emit('closeSidebar')
              this.initValues()
            })
            .catch(err => { 
              let {data} = err.response
              this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          }
          
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
